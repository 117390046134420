




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

@Component({
  components: {},
})
export default class Article extends Mixins(Mixin) {
  articleHtml = "...";

  refreshData(): void {
    let articleId = this.$route.query.id;
    if (articleId) {
      this.$api.cmsApi.article.documentArticle(
        String(articleId),
        ({ data }) => {
          this.articleHtml = this.dealPicture(data.content) || "";
        }
      );
    } else {
      console.error("URL 参数中没有文章 ID");
      this.articleHtml = "";
    }
  }
  dealPicture(desc: string | undefined): string {
    return desc
      ? desc.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
      : "";
  }
}
